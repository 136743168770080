import { ArrowBack, Error, VideoLibrary } from '@material-ui/icons';
import { Box, Button, Chip, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { VideoGalleryGridItem, VideoGalleryGridItemSkeleton } from '../VideoGalleryGridItem';

import { EmptyState } from '../../../../components/EmptyState';
import React from 'react';
import ReactPlayer from 'react-player';
import { getPageTitle } from '../../helpers';
import { useGetVideos } from '../../hooks/useGetVideos';
import { useStyles } from './styles';
import { useVideoTutorials } from '../../VideoTutorialsContext';

const NUMBER_OF_NEXT_VIDEOS = 4;

const NextVideos = () => {
  const { selectedVideo, setSelectedVideo } = useVideoTutorials();

  const {
    videos: NextVideos,
    loading,
    error,
  } = useGetVideos({
    search: '',
    contextPage: [selectedVideo.contextPage],
    after: selectedVideo.contextOrder,
    page: 0,
    perPage: NUMBER_OF_NEXT_VIDEOS,
    order: 'asc',
    orderBy: 'contextOrder',
  });

  if (error) {
    return (
      <EmptyState
        Icon={Error}
        title="Error"
        description="Failed to load next videos. Please try again later."
      />
    );
  }

  if (!NextVideos?.length && !loading) {
    return <EmptyState Icon={VideoLibrary} description="No next videos to watch" />;
  }

  return (
    <Grid container spacing={1}>
      {loading &&
        Array(NUMBER_OF_NEXT_VIDEOS)
          .fill(0)
          .map((_, i) => <VideoGalleryGridItemSkeleton key={i} />)}
      {!loading &&
        NextVideos?.map((video, index) => (
          <VideoGalleryGridItem key={index} video={video} onClick={setSelectedVideo} />
        ))}
    </Grid>
  );
};

export const VideoPlayer = () => {
  const classes = useStyles();
  const { selectedVideo, setSelectedVideo } = useVideoTutorials();
  const { title, url, description, contextPage, contextOrder } = selectedVideo;

  return (
    <Box display="flex" gridGap="25px" alignItems="flex-start">
      <Box flex={80}>
        <Box sx={{ marginBottom: '16px' }}>
          <Button
            variant="text"
            color="secondary"
            onClick={() => setSelectedVideo(null)}
            startIcon={<ArrowBack />}
          >
            <Typography variant="body1" color="secondary">
              Back to gallery
            </Typography>
          </Button>
        </Box>

        <ReactPlayer url={url} controls width="100%" height="100%" />
        <Box sx={{ marginTop: '16px' }}>
          <Box display="flex" gridGap={'20px'} alignItems="center">
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
            <Chip label={`${getPageTitle(contextPage)} #${contextOrder}`} color="accent" />
          </Box>
          <Paper className={classes.description} elevation={0}>
            <Typography variant="body1">{description}</Typography>
          </Paper>
        </Box>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box flex={20} sx={{ height: '70%' }}>
        <Box sx={{ marginBottom: '16px' }}>
          <Typography variant="h6">Next Videos</Typography>
        </Box>
        <NextVideos />
      </Box>
    </Box>
  );
};
