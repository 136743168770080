import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  pagination: {
    '& .MuiSelect-root': {
      borderRadius: '8px',
      boxShadow: 'none',
      border: `1px solid ${theme.palette.baseColors.deepSeaNavy.c25}`,
      backgroundColor: theme.palette.baseColors.white.c100,
    },
  },
}));
