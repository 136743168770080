import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    loader: {
      margin: '1rem',
    },
    popover: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '160px',
      background: 'white',
      borderRadius: '5px',
      boxShadow: '0px 2px 5px 0px ' + theme.palette.baseColors.deepSeaNavy.c100,
    },
    optionList: {
      flexGrow: '1',
      overflowY: 'auto',
      borderBottom: '1px solid lightgray',
      paddingBottom: '1rem',
      fontFamily: theme.fonts.base,
      backgroundColor: 'rgba(240, 240, 240,0.25)',
      '&::-webkit-scrollbar': {
        width: '16px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: '0 0 6px rgba(0,0,0,0.3)',
        borderRadius: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
        borderRadius: '10px',
        height: '20px',
        backgroundColor: '#2a2e49',
      },
    },
    sectionBottom: {
      display: 'flex',
      padding: '0.5rem 1rem',
    },
    label: {
      textTransform: 'uppercase',
      color: '#000000de',
      fontSize: theme.fontSizes.smaller,
    },
    input: {
      fontFamily: theme.fonts.base,
      borderRadius: 0,
    },
    button: {
      fontFamily: theme.fonts.base,
      fontWeight: 400,
      borderRadius: 0,
      fontSize: theme.fontSizes.smaller,
    },
    divide: {
      borderLeft: '1px solid lightgray',
      borderRadius: 0,
    },
    noResult: {
      padding: '1rem',
    },
    checkBox: {
      padding: '6px',
    },
    close: {
      minWidth: 'unset',
    },
  };
});
