import { createTheme } from '@material-ui/core/styles';

const baseColors = {
  deepSeaNavy: {
    c100: '#2a2f49',
    c75: '#5f6275',
    c50: '#9396a4',
    c25: '#cacbd1',
  },
  iceBlue: {
    c100: '#cdd7de',
    c75: '#d8e1e5',
    c50: '#e6ebee',
    c25: '#f2f5f6',
  },
  cloudGray: {
    c100: '#f0f0f0',
    c75: '#f3f3f3',
    c50: '#f7f7f7',
    c25: '#fbfbfb',
  },
  fireOrange: {
    c100: '#ff6250',
    c75: '#fe897b',
    c50: '#feb0a7',
    c25: '#ffd7d3',
  },
  skyBlue: {
    c100: '#5f91cb',
    c75: '#86acd8',
    c50: '#afc7e4',
    c25: '#d6e3f1',
  },
  earthGreen: {
    c100: '#006957',
    c75: '#27907e',
    c50: '#47a897',
    c25: '#99c4bc',
  },
  limeGreen: {
    c100: '#9Bb73c',
    c75: '#B4c95a',
    c50: '#cedc79',
    c25: '#e7ee97',
  },
  offWhite: {
    c100: '#ecf0f5',
  },
  gray: {
    c100: '#e0e0e0',
  },
  white: {
    c100: '#fff',
  },
  primaryBackgroundColor: '#ecf0f5',
};

const fontSizes = {
  biggest: '1.25rem',
  bigger: '1.125rem',
  base: '1rem',
  smaller: '0.875rem',
  smaller2: '0.8rem',
  smallest: '0.75rem',
};

const lineHeights = {
  bigger: 1.2,
  base: 1,
};

const defaultTheme = createTheme({
  fonts: {
    base: "'Barlow', sans-serif",
  },
  fontSizes,
  lineHeights,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1300,
      xl: 1620,
      xxl: 2000,
    },
  },
  palette: {
    baseColors,
    text: {
      primary: baseColors.deepSeaNavy.c100,
    },
    primary: {
      main: baseColors.primaryBackgroundColor,
      contrastText: baseColors.deepSeaNavy.c100,
    },
    primaryBackground: {
      backgroundColor: baseColors.primaryBackgroundColor,
      color: baseColors.deepSeaNavy.c100,
    },
    secondary: {
      main: baseColors.deepSeaNavy.c100,
      contrastText: baseColors.white.c100,
    },
    secondaryBackground: {
      backgroundColor: '#2a2e49',
      color: baseColors.white.c100,
    },
    transparentBackground: {
      backgroundColor: 'rgba(42, 46, 73, 0.75)',
      backdropFilter: 'blur(20px)',
      color: baseColors.white.c100,
    },
    transparentBackgroundAccent: {
      color: 'white',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    transparentBackgroundAccentButton: {
      color: 'white',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
      },
    },
    icedBackground: {
      backdropFilter: 'blur(20px)',
      backgroundColor: 'rgba(205, 215, 222, .75)',
    },
    selected: {
      backgroundColor: '#E8F2FA',
      color: '#000000',
    },
    accent: {
      backgroundColor: '#5f91cc',
      color: baseColors.white.c100,
    },
    accentText: {
      color: baseColors.fireOrange.c100,
    },
    secondaryAccentText: {
      color: '#507180',
    },
    alternateText: {
      color: '#5f91cc',
    },
    loading: {
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,.2)',
      opacity: '0.8',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    scrollbar: {
      '&::-webkit-scrollbar': {
        width: '16px',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
        backgroundColor: '#e5e5e5',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
        boxShadow: '0 0 6px rgba(0,0,0,0.3)',
        backgroundColor: '#2a2e49',
      },
      // for firefox
      'scrollbar-width': '16px',
      'scrollbar-color': '#2a2e49 white',
    },
    altScrollbar: {
      '&::-webkit-scrollbar': {
        width: '16px',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'none',
        boxShadow: 'none',
        borderRadius: '10px',
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        backgroundColor: 'transparent',
      },
      '&:hover': {
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': '0 0 6px rgba(0, 0, 0, 0.25)',
          boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.25)',
          backgroundColor: 'rgba(205, 215, 222, 0.1)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(205, 215, 222, 0.4)',
        },
        'scrollbar-color': 'rgba(205, 215, 222, 0.4) rgba(0, 0, 0, 0)',
      },
      // for firefox
      'scrollbar-width': '16px',
      'scrollbar-color': 'rgba(42, 46, 73, 0.0) rgba(42, 46, 73, 0.0)',
    },
    altScrollbarBlue: {
      '&::-webkit-scrollbar': {
        width: '16px',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'none',
        boxShadow: 'none',
        borderRadius: '10px',
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        backgroundColor: 'transparent',
      },
      '&:hover': {
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': '0 0 6px rgba(0, 0, 0, 0)',
          boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0)',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(42, 46, 73, 1)',
        },
        'scrollbar-color': 'rgba(42, 46, 73, 1) rgba(0, 0, 0, 0)',
      },
      // for firefox
      'scrollbar-width': '16px',
      'scrollbar-color': 'rgba(42, 46, 73, 0.0) rgba(42, 46, 73, 0.0)',
    },
    chip: {
      backgroundColor: 'rgba(33, 150, 243, 0.08)',
      color: '#2a2e49',
    },
    button: {
      backgroundColor: '#2a2e49',
      paddingLeft: '8px',
      paddingRight: '8px',
      color: baseColors.white.c100,
      lineHeight: lineHeights.base,
      '&:hover': {
        backgroundColor: '#5f91cc',
        color: baseColors.white.c100,
      },
    },
    buttonTransparent: {
      backgroundColor: 'transparent',
      border: 'solid 1px dimgrey',
      color: 'white',
      margin: '10px 0',
      '&:hover': {
        backgroundColor: 'transparent',
        border: 'solid 1px darkgrey',
      },
    },
    greyButton: {
      cursor: 'pointer',
      color: 'dimgrey',
      '&:hover': {
        color: 'lightgrey',
      },
    },
    actionButton: {
      cursor: 'pointer',
      color: '#ffa188',
      '&:hover': {
        color: baseColors.fireOrange.c100,
      },
    },
    altButton: {
      backgroundColor: 'rgba(33, 150, 243, 0.08)',
      paddingLeft: '8px',
      paddingRight: '8px',
      color: '#2a2e49',
      lineHeight: lineHeights.base,
      '&:hover': {
        backgroundColor: '#2a2e49',
        color: baseColors.white.c100,
      },
    },
    actionCustom: {
      backgroundColor: baseColors.fireOrange.c100,
      color: baseColors.white.c100,
      '&:hover': {
        backgroundColor: '#ffa188',
      },
    },
    greenButton: {
      backgroundColor: baseColors.earthGreen.c100,
      color: baseColors.white.c100,
      '&:hover': {
        backgroundColor: baseColors.earthGreen.c50,
      },
    },
    mapTab: {
      color: '#2a2e49',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      margin: '0 5px 5px 5px',
      flex: '1 1 auto',
      overflow: 'auto',
    },
    mapInfoSection: {
      color: '#2a2e49',
      display: 'flex',
      flexDirection: 'column',
      margin: '5px',
    },
    // Similar to modal prop keepMounted, this keeps a component 'mounted'
    // even if we would want it hidden in the DOM.
    // This allows us to set a transition on the visiblity when enabling the component.
    keepMounted: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s, opacity 0s',
      transitionDelay: '0s',
    },
    keepMountedEnabled: {
      visibility: 'visible',
      opacity: 1,
      transition: 'visibility 0s, opacity .5s linear',
      transitionDelay: '0.3s',
    },
    // Useful for hiding a component in the DOM without unmounting it
    // or affecting the layout of the page.
    hiddenContainer: {
      position: 'absolute',
      visibility: 'hidden',
    },
  },
  modal: {
    backgroundColor: '#ecf0f5',
    color: '#2a2e49',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '&:focus': {
      outline: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalButton: {
    backgroundColor: baseColors.fireOrange.c100,
    borderRadius: 0,
    alignSelf: 'flex-end',
    color: baseColors.white.c100,
    '&:hover': {
      backgroundColor: '#ffa188',
    },
  },
  appBarDropDown: {
    color: '#2a2e49',
    backgroundColor: baseColors.white.c100,
    zIndex: 1,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '.8rem',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'white',
        boxShadow:
          '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
      },
    },
    MuiSwitch: {
      track: {
        backgroundColor: 'lightgrey',
      },
      colorPrimary: {
        color: 'darkgrey',
        '&$checked': {
          color: baseColors.skyBlue.c75,
        },
        '&$checked + $track': {
          backgroundColor: '#292E48',
        },
      },
      colorSecondary: {
        color: 'darkgrey',
        '&$checked': {
          color: '#292E48',
        },
        '&$checked + $track': {
          backgroundColor: '#292E48',
        },
      },
    },
    MuiSnackbarContent: {
      message: {
        maxWidth: '375px',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: "'Barlow', sans-serif",
        '&$focused': {
          color: '#30a8d9',
        },
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: baseColors.deepSeaNavy.c100,
        '&$checked': {
          color: baseColors.deepSeaNavy.c100,
        },
      },
      colorSecondary: {
        color: baseColors.skyBlue.c75,
        '&$checked': {
          color: baseColors.skyBlue.c75,
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: baseColors.skyBlue.c75,
        '&$checked': {
          color: baseColors.skyBlue.c75,
        },
      },
      colorSecondary: {
        // TODO: Why are checkboxes defaulting to color="secondary"?
        color: baseColors.deepSeaNavy.c100,
        '&$checked': {
          color: baseColors.deepSeaNavy.c100,
        },
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: '#2a2e49',
        color: baseColors.white.c100,
        marginBottom: '1px',
        boxShadow: 'none',
      },
    },
    MuiAccordionSummary: {
      expandIcon: {
        color: 'white',
      },
    },
    MuiAccordionDetails: {
      root: {
        backgroundColor: 'white',
        color: '#2a2e49',
        display: 'flex',
        flexDirection: 'column',
      },
    },
    MuiPaper: {
      root: {
        color: '#2a2e49',
      },
    },
  },
});

const { breakpoints, overrides } = defaultTheme;
const sizes = {
  scrollableFullPage: {
    height: '100%',
    minHeight: 'calc(100vh - 66px - 29.25px)', // vh - height of appbar - height of footer
    width: '100%',
    overflow: 'auto',
  },
  fullPage: {
    height: 'calc(100vh - 66px - 29.25px)', // vh - height of appbar - height of footer
    width: '100%',
    [breakpoints.down('md')]: {
      height: 'calc(100vh - 64px - 23.94px)',
    },
  },
  fullPageNoAppBar: {
    height: 'calc(100vh - 29.25px)', // vh - height of footer
    width: '100%',
    [breakpoints.down('md')]: {
      height: 'calc(100vh - 23.94px)',
    },
  },
};

const theme = {
  ...defaultTheme,
  menuButtonFontStyles: {
    lineHeight: lineHeights.bigger,
    fontSize: fontSizes.smaller,
    fontWeight: 500,
    textTransform: 'none',
    '@media (max-width: 1030px)': {
      fontSize: fontSizes.smaller2,
    },
    '@media (max-width: 970px)': {
      fontSize: fontSizes.smallest,
    },
    '@media (max-width: 959px)': {
      fontSize: fontSizes.smaller2,
    },
    '@media (max-width: 810px)': {
      fontSize: fontSizes.smallest,
    },
    '@media (max-width: 750px)': {
      fontSize: fontSizes.smaller,
    },
  },
  appBarDropDownItem: {
    fontSize: fontSizes.base,
  },
  mapPanel: {
    color: '#2a2e49',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    borderRadius: '10px',
    boxShadow: '1px 1px 2px 2px rgba(0, 0, 0, .5)',
    width: '285px',
    [breakpoints.down('md')]: {
      height: `calc(${sizes.fullPage[breakpoints.down('md')].height} - 45px)`,
      width: '200px',
    },
    height: `calc(${sizes.fullPage.height} - 45px)`, // fullPage - margin
    margin: '10px',
    boxSizing: 'border-box',
  },
  sizes,
  overrides: {
    ...overrides,
    MuiAutocomplete: {
      clearIndicator: {
        [breakpoints.down('md')]: {
          width: '18px',
          height: '18px',
        },
      },
      endAdornment: {
        [breakpoints.down('md')]: {
          top: 'calc(50% - 10px)',
        },
      },
      input: {
        fontFamily: "'Barlow', sans-serif",
        fontSize: fontSizes.base,
      },
      popper: {
        [breakpoints.down('md')]: {
          fontSize: '9px',
        },
      },
      tag: {
        fontSize: fontSizes.smaller2,
        [breakpoints.down('md')]: {
          fontSize: '10px',
        },
      },
    },
    MuiButton: {
      root: {
        fontFamily: "'Barlow', sans-serif",
        fontSize: fontSizes.smaller2,
        padding: '5px 10px',
        [breakpoints.down('md')]: {
          padding: '2px 4px',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: "'Barlow', sans-serif",
      },
    },
    MuiChip: {
      root: {
        height: '25px',
      },
      label: {
        fontFamily: "'Barlow', sans-serif",
        fontSize: fontSizes.smallest,
        [breakpoints.down('md')]: {
          fontSize: '10px',
        },
      },
      deleteIcon: {
        [breakpoints.down('md')]: {
          width: '15px',
          height: '15px',
        },
      },
    },
    MuiFab: {
      root: {
        minHeight: 0,
        width: '48px',
        height: '48px',
        [breakpoints.down('md')]: {
          width: '36px',
          height: '36px',
        },
      },
      sizeSmall: {
        [breakpoints.down('md')]: {
          width: '28px',
          height: '28px',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontFamily: "'Barlow', sans-serif",
        fontSize: fontSizes.smaller2,
      },
    },
    MuiIconButton: {
      root: {
        [breakpoints.down('md')]: {
          padding: '9px',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#2a2e49',
        fontWeight: 'bold',
        letterSpacing: '0.26px',
        textAlign: 'center',
        fontSize: fontSizes.smaller2,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        '& legend': {
          fontSize: '0.50em',
          '& span': {
            paddingLeft: 0,
            paddingRight: '20px',
            [breakpoints.down('lg')]: {
              paddingRight: '12px',
            },
            [breakpoints.down('md')]: {
              paddingRight: 0,
            },
          },
        },
      },
    },
    MuiSelect: {
      root: {
        boxShadow:
          '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
      },
      select: {
        backgroundColor: '#fafafa',
        boxSizing: 'border-box',
        fontSize: fontSizes.base,
        borderRadius: '5px',
      },
      icon: {
        color: '#2a2e49',
      },
    },
    MuiSpeedDialIcon: {
      root: {
        [breakpoints.down('md')]: {
          width: '18px',
          height: '18px',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        [breakpoints.down('md')]: {
          width: '18px',
          height: '18px',
        },
      },
    },
    MuiToolbar: {
      gutters: {
        [breakpoints.down('sm')]: {
          paddingLeft: '5px',
          paddingRight: '5px',
        },
      },
    },
    MuiTypography: {
      h2: {
        fontFamily: "'Barlow', sans-serif",
        fontWeight: 'bold',
      },
      h3: {
        fontFamily: 'crimson',
        fontWeight: 'bold',
        fontSize: '36px',
      },
      h4: {
        fontFamily: 'crimson',
        fontWeight: 'bold',
      },
      h6: {
        fontFamily: 'crimson',
        fontWeight: 'bold',
      },
      body1: {
        fontFamily: "'Barlow', sans-serif",
        fontSize: fontSizes.smaller2,
        [breakpoints.down('md')]: {
          fontSize: fontSizes.smaller2,
        },
        [breakpoints.down('sm')]: {
          fontSize: '10px',
        },
      },
      body2: {
        fontFamily: "'Barlow', sans-serif",
        fontSize: '11px',
        [breakpoints.down('md')]: {
          fontSize: '9px',
        },
        [breakpoints.down('sm')]: {
          fontSize: '8px',
        },
      },
      caption: {
        fontFamily: "'Barlow', sans-serif",
        fontSize: fontSizes.smaller2,
      },
      overline: {
        fontFamily: "'Barlow', sans-serif",
        fontSize: '11px',
        [breakpoints.down('md')]: {
          fontSize: '10px',
        },
        [breakpoints.down('sm')]: {
          fontSize: '9px',
        },
      },
      subtitle1: {
        fontFamily: "'Barlow', sans-serif",
        fontSize: fontSizes.smaller,
        [breakpoints.down('md')]: {
          fontSize: fontSizes.smaller2,
        },
        [breakpoints.down('sm')]: {
          fontSize: fontSizes.smallest,
        },
      },
      subtitle2: {
        fontFamily: "'Barlow', sans-serif",
      },
    },
  },
};

export default theme;
