import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  gridItem: {
    maxWidth: '405px',
    width: '100%',
  },
  card: {
    '&:hover': {
      boxShadow: theme.shadows[10],
    },
  },
}));
