import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import BookIcon from '@material-ui/icons/Book';
import ChatIcon from '@material-ui/icons/Chat';
import Divider from '@material-ui/core/Divider';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SnackbarContext from '../SnackbarContext';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { requestTrainingMailTo } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    padding: '6px 1rem',
    '& > .MuiButton-label': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiButton-iconSizeMedium': {
      padding: '9px',
      borderRadius: '50px',
    },
  },
  trainingButton: {
    '& .MuiButton-iconSizeMedium': {
      background: theme.palette.baseColors.deepSeaNavy.c100,
      color: theme.palette.baseColors.white.c100,
    },
  },
  chatButton: {
    color: theme.palette.baseColors.fireOrange.c100,
    '& .MuiButton-iconSizeMedium': {
      ...theme.palette.actionCustom,
    },
  },
  info: {
    textTransform: 'none',
  },
  support: {
    color: '#2a2e49',
  },
  contact: {
    ...theme.palette.accentText,
    fontSize: '10px',
  },
  supportContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 20px',
    [theme.breakpoints.down('md')]: {
      padding: '10px 20px',
    },
  },
  supportTextContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  supportButtonContainer: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

export default () => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);

  const copyEmail = () => {
    const el = document.createElement('input');
    el.value = 'info@welligence.com';
    el.id = 'url-input';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    el.remove();
    setSnack({
      open: true,
      severity: 'success',
      message: 'Copied URL to clipboard!',
    });
  };

  return (
    <div>
      <Divider />

      <Button
        className={clsx(classes.button, classes.trainingButton)}
        href={requestTrainingMailTo}
        endIcon={<BookIcon fontSize="small" />}
      >
        Request training
      </Button>

      <Button
        className={clsx(classes.button, classes.chatButton)}
        href={'mailto:info@welligence.com'}
        endIcon={<ChatIcon fontSize="small" />}
      >
        Customer Support
      </Button>

      <Button
        className={clsx(classes.button, classes.info)}
        onClick={copyEmail}
        endIcon={<FileCopyIcon fontSize="small" color="secondary" />}
      >
        info@welligence.com
      </Button>
    </div>
  );
};
