import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import MuiPopper from '@material-ui/core/Popover';
import { Popover } from './components/Popover';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import { useStyles } from './AutoCompleteExcelLike.style';

const AutoCompleteExcelLikeInner = ({
  isLoading,
  options,
  setOptions,
  popoverHeightReduction,
  groupOptionKey,
  label,
  styles = {},
  setOptionsOnModalClose,
  groupsOpenedByDefault,
  groupByName,
  groupSorter,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [top, setTop] = useState(null);
  const [offsetPopper, setOffsetPopper] = useState(false);
  const heightSizingRef = useRef(null);

  useEffect(() => {
    if (heightSizingRef.current) {
      const { top, height } = heightSizingRef.current.getBoundingClientRect();
      setTop(top + height);
    }
  }, [heightSizingRef.current]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const classes = useStyles();

  const numberOfChosenOptions = useMemo(() => {
    return (options || []).filter(({ isChosen }) => !!isChosen).length;
  }, [options]);

  return (
    <>
      <div ref={heightSizingRef} className={classes.wrapperAutoCompleteExcelLike} style={styles}>
        <button
          type="button"
          onClick={(e) => {
            setIsOpen(!isOpen);
            setAnchorEl(e.currentTarget);

            /* This fixes the popper alignment issue on portfolio analysis once the anchor is placed
            too much on the left side (less than left: 16px)*/
            const buttonOffsetLeft = e.currentTarget.getBoundingClientRect().left;
            setOffsetPopper(buttonOffsetLeft === 8);
          }}
          className={clsx(classes.box, { [classes.boxOpen]: isOpen })}
        >
          <div className={classes.label}>{label}</div>
          <div className={classes.rightPart}>
            {!!numberOfChosenOptions && (
              <span className={classes.chosen}>{numberOfChosenOptions}</span>
            )}
            <ArrowDropDown className={clsx({ [classes.arrowOpen]: isOpen })} />
          </div>
        </button>
      </div>
      <MuiPopper
        className={clsx(classes.popper, { [classes.popperAlignment]: !!offsetPopper })}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setOptionsOnModalClose && setOptionsOnModalClose();
        }}
      >
        <Popover
          top={top}
          popoverHeightReduction={popoverHeightReduction}
          onClose={onClose}
          options={options || []}
          setOptions={setOptions}
          loading={isLoading}
          groupOptionKey={groupOptionKey}
          label={label}
          groupsOpenedByDefault={groupsOpenedByDefault}
          groupByName={groupByName}
          groupSorter={groupSorter}
        />
      </MuiPopper>
    </>
  );
};

export const AutoCompleteExcelLike = ({
  getData,
  mapQueryData,
  groupOptionKey,
  propVariables,
  onChange,
  value,
  popoverHeightReduction,
  label,
  groupsOpenedByDefault,
  groupByName,
  groupSorter,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [options, setOptions] = useState(null);

  const setOptionsLocal = (v) => {
    setOptions(v);
    setTimeout(() => onChange(v.filter(({ isChosen }) => isChosen)), 0);
  };

  const { data } = useQuery(getData, {
    variables: propVariables,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data) {
      const entitiesChosenFromProp = value.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
      const entityData = mapQueryData(data);
      const mergedFetchedEntitiesWithProp = entityData.map((entity) => {
        const isChosen = !!entitiesChosenFromProp[entity.id];
        delete entitiesChosenFromProp[entity.id];
        return {
          displayName: entity.displayName || entity.name,
          ...entity,
          isChosen,
        };
      });
      const entitiesWithAllChosen = mergedFetchedEntitiesWithProp.concat(
        Object.values(entitiesChosenFromProp),
      );
      setOptions(entitiesWithAllChosen);
      setIsLoading(false);
    }
  }, [data, value]);
  return (
    <AutoCompleteExcelLikeInner
      isLoading={isLoading}
      options={options}
      setOptions={setOptionsLocal}
      popoverHeightReduction={popoverHeightReduction}
      label={label}
      groupsOpenedByDefault={groupsOpenedByDefault}
      groupByName={groupByName}
      groupSorter={groupSorter}
      groupOptionKey={groupOptionKey}
    />
  );
};

export const AutoCompleteExcelLikeLocalData = ({
  options,
  setOptions,
  isLoading,
  groupOptionKey,
  popoverHeightReduction,
  inModal,
  label,
  groupsOpenedByDefault,
  groupByName,
  groupSorter,
}) => {
  const [values, setValues] = useState(options);
  useEffect(() => setValues(options), [options]);

  const setOptionsOnModalClose = () => setOptions(values);

  return (
    <AutoCompleteExcelLikeInner
      isLoading={isLoading}
      options={values}
      setOptions={setValues}
      popoverHeightReduction={popoverHeightReduction}
      inModal={inModal}
      groupOptionKey={groupOptionKey}
      label={label}
      styles={{ width: '10rem' }}
      setOptionsOnModalClose={setOptionsOnModalClose}
      groupsOpenedByDefault={groupsOpenedByDefault}
      groupByName={groupByName}
      groupSorter={groupSorter}
    />
  );
};
