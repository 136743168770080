import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  icon: {
    fontSize: '120px',
    color: theme.palette.baseColors.deepSeaNavy.main,
  },
}));
