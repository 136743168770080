import { Box, Paper, Typography } from '@material-ui/core';
import { VideoTutorialsProvider, useVideoTutorials } from './VideoTutorialsContext';

import { EmptyState } from '../../components/EmptyState';
import { Error } from '@material-ui/icons';
import React from 'react';
import { VideoGallery } from './components/VideoGallery';
import { VideoPlayer } from './components/VideoPlayer';
import { useDebounce } from '../../utils/useDebounce';
import { useGetVideos } from './hooks/useGetVideos';
import { useStyles } from './styles';

const VideoTutorialsPage = () => {
  const classes = useStyles();
  const { selectedVideo, search, page, perPage, order, orderBy } = useVideoTutorials();
  const { videos, totalCount, loading, error } = useGetVideos({
    search: useDebounce(search, 500),
    page,
    perPage,
    order,
    orderBy,
  });

  return (
    <Box className={classes.root}>
      <Paper className={classes.content} elevation={1}>
        <Box sx={{ paddingBottom: '16px' }}>
          <Typography variant="h4">Video Tutorials</Typography>
        </Box>
        {error && (
          <EmptyState
            Icon={Error}
            title="Error"
            description="An error occurred. Please try again later."
          />
        )}
        {!error && !selectedVideo && <VideoGallery {...{ videos, totalCount, loading }} />}
        {!error && selectedVideo && <VideoPlayer />}
      </Paper>
    </Box>
  );
};

export const VideoTutorials = () => {
  return (
    <VideoTutorialsProvider>
      <VideoTutorialsPage />
    </VideoTutorialsProvider>
  );
};
