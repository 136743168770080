import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { ORDER_BY_OPTIONS, ORDER_OPTIONS } from '../../types';

import React from 'react';
import { Search } from '@material-ui/icons';
import { useStyles } from './styles';
import { useVideoTutorials } from '../../VideoTutorialsContext';

export const VideoGalleryControls = () => {
  const classes = useStyles();
  const { search, setSearch, orderBy, setOrderBy, order, setOrder } = useVideoTutorials();

  return (
    <Box display="flex" justifyContent="space-between" flexWrap="wrap">
      <FormControl className={classes.search}>
        <TextField
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Filter by title or description"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <Box display="flex" justifyContent="flex-end" gridGap="24px">
        <FormControl variant="outlined" className={classes.select}>
          <InputLabel id="order-by">ORDER BY</InputLabel>
          <Select
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
            labelId="order-by"
            label="ORDER BY"
          >
            {ORDER_BY_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.select}>
          <InputLabel id="order">ORDER</InputLabel>
          <Select
            value={order}
            onChange={(e) => setOrder(e.target.value)}
            labelId="order"
            label="ORDER"
          >
            {ORDER_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
