import { Box, Typography } from '@material-ui/core';

import React from 'react';
import { useStyles } from './styles';

export const EmptyState = ({ Icon, title, description }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Icon className={classes.icon} />
      <Typography variant="h5" color="secondary" sx={{ marginTop: 2 }}>
        {title}
      </Typography>
      <Typography variant="body" color="textSecondary" align="center" sx={{ marginTop: 1 }}>
        {description}
      </Typography>
    </Box>
  );
};
